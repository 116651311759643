import LinkListBlock from 'libs/wordpress/content/cmsBlocks/LinkListBlock';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import React from 'react';
import moduleMargins from 'config/theme/moduleMargins';

const LinkListModule = ({ ...rest }) => (
    <MaxWidthWrapper includeContentMargins as="section" my={moduleMargins.md}>
        <LinkListBlock {...rest} />
    </MaxWidthWrapper>
);

export default LinkListModule;

/* eslint-disable camelcase */
import LinkList from 'components/contentBlocks/LinkList';
import PropTypes from 'prop-types';
import React from 'react';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import transformLink from 'libs/wordpress/utils/transformLink';

const LinkListTransformer = ({ content }) => {
    const { background_portrait_landscape: backgroundPortraitLandscape, links, tag, top_link: topLink } = content;
    const landscapeBackground = backgroundPortraitLandscape.landscape.background_image;
    const portraitBackground = backgroundPortraitLandscape.portrait.background_image;

    const transformedlinks = links.map(({ background_portrait_landscape: backgroundPortraitLandscape, link }) => {
        const landscapeBackground = backgroundPortraitLandscape.landscape.background_image;
        const portraitBackground = backgroundPortraitLandscape.portrait.background_image;

        return {
            link: transformLink(link),
            landscapeBackground: transformBackgroundImage(landscapeBackground),
            portraitBackground: transformBackgroundImage(portraitBackground),
        };
    });

    return (
        <LinkList
            defaultLandscapeBackground={transformBackgroundImage(landscapeBackground)}
            defaultPortraitBackground={transformBackgroundImage(portraitBackground)}
            links={transformedlinks}
            tag={decodeHTMLEntities(tag)}
            topLink={transformLink(topLink)}
        />
    );
};

LinkListTransformer.propTypes = {
    content: PropTypes.shape({
        background_portrait_landscape: PropTypes.shape({
            landscape: PropTypes.object,
            portrait: PropTypes.object,
        }).isRequired,
        links: PropTypes.arrayOf(PropTypes.object),
        top_link: PropTypes.object,
        tag: PropTypes.string,
    }).isRequired,
};

export default LinkListTransformer;

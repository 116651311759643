import React, { useState } from 'react';
import { above, below, media } from 'utils/mediaqueries';
import { imageProp, mediaQueryProp } from 'utils/proptypes/modules/mediaProps';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundImage from 'components/background/BackgroundImage';
import BaseLink from 'components/base/Link';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import TextLink from 'components/text/TextLink';
import colors from 'config/theme/colors';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const Div = styled('div')``;

const Links = styled('div')`
    display: flex;
    flex-direction: row;

    ${above.md} {
        flex-direction: column;
        align-items: flex-start;
    }

    ${below.md} {
        &::before,
        &::after {
            display: block;
            content: '';
            ${responsiveBreakpointsStyle(
                ['12px', null, '24px'],
                'width: {value}; max-width: {value}; flex: 0 0 {value};'
            )}
        }
    }
`;

const Link = styled(BaseLink)`
    position: relative;
    display: flex;
    flex-direction: column;
    transition: color ${transitions.primary};
    overflow: hidden;

    :not(:last-of-type) {
        margin-right: 16px;

        ${above.sm} {
            margin-right: 24px;
        }

        ${above.md} {
            margin: 0;
            padding-bottom: 24px;
        }
    }

    ${media.hover} {
        :hover {
            color: ${colors.black};
        }
    }

    &::before {
        content: attr(data-number);
        display: block;
        width: 100%;
        font-size: 1.4rem;

        ${above.md} {
            width: auto;
            order: 1;
            font-size: 1.8rem;
        }
    }

    ${above.md} {
        flex-direction: row;
        line-height: 1;
    }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${above.md} {
        width: calc(100% - 24px); /* 24px = 12px column on each side */
    }
`;

const mediaQueries = [
    /* 0  <-  xs */ { w: 375, q: 70 },
    /* xs <-> sm */ { w: 768, q: 70 },
    /* sm <-> md */ { w: 1024, q: 70 },
    /* md <-> lg */ { w: 1440 / 3, q: 70 },
    /* lg <-> xl */ { w: 1920 / 3, q: 70 },
    /* xl ->     */ { w: 1920 / 3, q: 70 },
];

const LinkList = ({
    defaultLandscapeBackground,
    defaultPortraitBackground,
    links,
    mediaQuery = mediaQueries,
    tag,
    topLink,
}) => {
    const isAboveMd = useAboveBreakpoint('md');

    const [backgrounds, setBackgrounds] = useState({
        landscape: null,
        portrait: null,
    });

    return (
        <Div>
            <Div display="flex" justifyContent="space-between" alignItems="center" mb={['12px', null, null, '56px']}>
                {tag && <Heading size="xs">{tag}</Heading>}
                {topLink && <TextLink to={topLink.to}>{topLink.label}</TextLink>}
            </Div>
            <Div
                alignItems="flex-start"
                display="flex"
                flexDirection={['column', null, null, 'row']}
                mx={[null, null, null, '-12px']}
            >
                <AspectWrapper
                    order={[2, null, null, 3]}
                    width={['100%', null, null, '50%', '33.33%']}
                    px={[null, null, null, '12px']}
                    ratio={[3 / 4, 4 / 3, 3 / 4]}
                >
                    <StyledBackgroundImage
                        backgroundPosition={[
                            defaultPortraitBackground.backgroundPosition,
                            null,
                            defaultLandscapeBackground.backgroundPosition,
                            defaultPortraitBackground.backgroundPosition,
                        ]}
                        backgroundSize={[
                            defaultPortraitBackground.backgroundSize,
                            null,
                            defaultLandscapeBackground.backgroundSize,
                            defaultPortraitBackground.backgroundSize,
                        ]}
                        query={mediaQuery}
                        src={[
                            defaultPortraitBackground.url,
                            null,
                            defaultLandscapeBackground.url,
                            defaultPortraitBackground.url,
                        ]}
                    />
                    {/* Only used above md */}
                    {backgrounds.landscape && backgrounds.portrait && (
                        <StyledBackgroundImage
                            src={[null, null, null, backgrounds.portrait.url]}
                            query={mediaQuery}
                            backgroundSize={[null, null, null, backgrounds.portrait.backgroundSize]}
                            backgroundPosition={[null, null, null, backgrounds.portrait.backgroundPosition]}
                        />
                    )}
                </AspectWrapper>
                <Div
                    order={[3, null, 2]}
                    width={['calc(100% + 24px)', null, null, '50%', '66.66%']}
                    px={[null, null, null, '12px']}
                    m={['12px -12px 0', null, null, '0']}
                >
                    <ScrollWrapper
                        displayFadeEffect
                        direction="horizontal"
                        scrollContentProps={{
                            alignItems: 'flex-start',
                            flexDirection: ['row', null, null, 'column'],
                            flexWrap: ['noWrap', null, null, 'wrap'],
                        }}
                    >
                        <Links>
                            {links.map(({ link, landscapeBackground, portraitBackground }, index) => (
                                <Link
                                    as={BaseLink}
                                    color={backgrounds.landscape || backgrounds.portrait ? colors.grey6 : colors.black}
                                    data-number={index < 10 ? `0${index + 1}` : index + 1}
                                    fontSize={['3.6rem', null, null, '6.4rem']}
                                    key={link.label}
                                    lineHeight="1"
                                    to={link.to}
                                    onMouseEnter={() =>
                                        isAboveMd
                                            ? setBackgrounds({
                                                  landscape: landscapeBackground,
                                                  portrait: portraitBackground,
                                              })
                                            : null
                                    }
                                    onMouseLeave={() =>
                                        isAboveMd
                                            ? setBackgrounds({
                                                  landscape: null,
                                                  portrait: null,
                                              })
                                            : null
                                    }
                                >
                                    {link.label}
                                </Link>
                            ))}
                        </Links>
                    </ScrollWrapper>
                </Div>
            </Div>
        </Div>
    );
};

LinkList.propTypes = {
    defaultLandscapeBackground: imageProp,
    defaultPortraitBackground: imageProp,
    links: PropTypes.arrayOf(
        PropTypes.exact({
            landscapeBackground: imageProp,
            link: PropTypes.exact({
                label: PropTypes.string,
                to: PropTypes.string,
            }),
            portraitBackground: imageProp,
        })
    ),
    mediaQuery: mediaQueryProp,
    tag: PropTypes.string,
    topLink: PropTypes.exact({
        label: PropTypes.string,
        to: PropTypes.string,
    }),
};

export default LinkList;
